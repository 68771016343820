<!-- 积分管理-积分订单 -->

<template>
	<div class="points-order main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)"
				:apiName="PointsManage.getIntegralOrderLists" :columns="tableColumns" :extraParame="extraParame"
				:filters="filters">
			</common-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		nextTick,
	} from "vue";
	import { PointsManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 表格对象 */
	const roleTable = ref(null);
	const extraParame = ref({
		in_id: '',
	});

	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "in_id",
			label: "商品ID",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_username",
			label: "用户昵称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_mobile",
			label: "手机号",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "in_gname",
			label: "奖品",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "ine_integral",
			label: "消耗积分",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "ine_sn",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_ine_ctime",
			label: "兑换时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "used",
			label: "是否使用",
			color: "--text-color",
			showTooltip: true,
		},
	]);
	/** 筛选条件列表 */
	const filters = ref([
		{
			name: "kws",
			filterType: "search",
			value: "",
			placeholder: "请输入用户手机号或订单号",
		},
		{
			filterType: "date",
			name: "in_exc_stime",
			name2: "in_exc_etime",
			value: [],
			// value: [],
			startText: "兑换开始时间",
			endText: "兑换结束时间",
			placeholder: "请选择时间范围",
			type: "daterange",
		},

	]);
	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.points-order {
		font-family: "Source Han Sans CN";

		.flex {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.top-title {
			height: 32px;
			line-height: 32px;
		}

		.vertical {
			border: 2px solid #1ACB95;
			margin-left: 0;
		}

		.preview-btn {
			width: 60px;
		}

		.el-table__footer-wrapper tbody td.el-table__cell {
			background-color: #fff;
			border-color: #fff;
		}

		.el-table__fixed::before,
		.el-table::before,
		.el-table__fixed-right::before {
			background-color: #fff;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;

				.el-table__row {
					.el-table__cell {
						padding: 9px 0 !important;
					}
				}

			}
		}
	}
</style>